import React from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import ImageModal from '../components/ImageModal';
import Footer from '../components/Footer';
export default (props) => {
  console.log(props);
  const album = props.data.albumsJson;

  return (
    <Layout>
      <PageHeader>
        <h2 className="text-6xl">{album.title}</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/multimedia" className="underline">Multimedia</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="#" >{album.title}</Link>
        </p>
        {/* <p className="my-4 max-w-xl mx-auto opacity-75 text-sm">{category.description}</p> */}
      </PageHeader>
      <Container>
        <div className="flex flex-wrap items-center">
          {
            album.photos.map((el,i)=>(
              <div className="w-full sm:w-1/2 md:w-1/4 m-4" key={i}>
                <ImageModal src={require(`../images/${album.folder}/${el}`)} />
              </div>
            ))
          }
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query( $slug: String){

    albumsJson(slug:{eq:$slug}){
      title
      photos
      folder
    }
  }
`;